'use client';

import clsx from 'clsx';
import Link from 'next/link';
import React, { Fragment, useEffect, useState } from 'react';

import Close from '@/assets/images/modal-close.svg';
import { TextBlock } from '@/components/text-block/TextBlock.component';
import { BANNER_LS_KEY } from '@/constants';

import { SiteBannerType } from '../../types/SiteBanner.d';

import styles from './SiteBanners.module.scss';

type SiteBannersProps = {
    banners: SiteBannerType[];
    className?: string;
};

export const SiteBanners = ({ banners, className }: SiteBannersProps) => {
    const [activeBanners, setActiveBanners] = useState<SiteBannerType[]>([]);

    const handleDismiss = (id: number) => {
        const tempBanners = [...activeBanners];

        const dismissedBanners = new Set(
            JSON.parse(localStorage.getItem(BANNER_LS_KEY) || '[]')
        ).add(id);

        localStorage.setItem(BANNER_LS_KEY, JSON.stringify([...dismissedBanners]));

        const banner = tempBanners.find((banner: SiteBannerType) => banner.id === id);

        if (banner) banner.hidden = true;

        setActiveBanners([...tempBanners]);
    };

    useEffect(() => {
        if (!banners) return;
        const dismissed = JSON.parse(
            (typeof window !== 'undefined' && window.localStorage.getItem(BANNER_LS_KEY)) || '[]'
        );

        setActiveBanners(banners.filter((banner) => !dismissed.includes(banner.id)) || []);
    }, [banners]);

    return activeBanners?.length > 0 ? (
        <ul className={clsx(styles.banners, className)}>
            {activeBanners.map((banner) =>
                !banner.hidden ? (
                    <li
                        key={banner.id}
                        className={clsx(styles.banner, {
                            [styles.alertBanner]: banner.type.toLowerCase() === 'alert',
                            [styles.noticeBanner]: banner.type.toLowerCase() === 'notice',
                        })}
                        data-testid="site-banner"
                    >
                        <div className={styles.textBtnWrapper}>
                            <div
                                className={clsx(styles.text, {
                                    [styles.clickable]: banner.heading_link !== '',
                                })}
                            >
                                {banner.heading_link && (
                                    <Link
                                        className={styles.headingLink}
                                        href={banner.heading_link || '#'}
                                        onClick={() => handleDismiss(banner.id)}
                                        target="_blank"
                                    />
                                )}
                                <div className={styles.headingText}>{banner.heading_text}</div>
                                {banner.details !== '' && (
                                    <TextBlock
                                        className={styles.details}
                                        content={banner.details}
                                    />
                                )}
                            </div>
                        </div>
                        <button
                            className={styles.hide}
                            onClick={() => handleDismiss(banner.id)}
                            data-testid="dismiss-banner"
                        >
                            <Close className={styles.closeIcon} />
                        </button>
                    </li>
                ) : (
                    <Fragment key={banner.id} />
                )
            )}
        </ul>
    ) : (
        <></>
    );
};
