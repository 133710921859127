'use client';

import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { useEffect, useId, useMemo, useRef, useState } from 'react';

import Arrow from '@/assets/images/arrow.svg';
import Globe from '@/assets/images/globe.svg';

import { defaultLanguage } from '../../helpers/defaultLanguages';
import { useLanguageStore } from '../../store/language-store-provider';

import './LocalePicker.scss';

type LocalePickerProps = {
    inline?: boolean;
    mobile?: boolean;
    className?: string;
};

export const LocalePicker = ({ inline, mobile, className }: LocalePickerProps) => {
    const uid = useId();
    const router = useRouter();
    const container = useRef<HTMLDivElement | null>(null);

    const { language, languages, supported, setLanguage } = useLanguageStore((state) => state);
    const [open, setOpen] = useState(false);

    const options = useMemo(
        () =>
            languages.reduce((langs: Array<any>, lang: any) => {
                if (!supported.includes(lang.iso)) return langs;

                return (langs = [
                    ...langs,
                    {
                        label: lang.label,
                        value: lang.iso,
                    },
                ]);
            }, []),
        [languages, supported]
    );

    const defaultOption =
        options.find((option) => option.value === language.iso) ||
        options.find((option) => option.value === 'en') ||
        options[0];

    const handleOpenClick = () => {
        setOpen(!open);
    };

    const handleLanguageSelect = (option: { label: string; value: string }) => {
        const newLanguage = languages.find((lang) => lang.iso === option.value) || defaultLanguage;

        // The current URL path can sometimes get mismatched with the expected language path.
        // => Find any matching path from standard URLs and replace it.
        const newPath = window.location.href
            .replace(window.location.origin, '')
            .replace(
                languages
                    .map((lang) => lang.path)
                    .find((path) =>
                        window.location.href.replace(window.location.origin, '').includes(path)
                    ) || '',
                newLanguage.path || ''
            );

        setLanguage(newLanguage);
        setOpen(false);

        router.push(newPath);

        // => Focus the container to allow keyboard navigation.
        container.current?.focus();

        // The layout.tsx doesn't update automatically on language change.
        // => Update the HTML language and direction to new values manually.
        document.documentElement.lang = newLanguage.iso;
        document.documentElement.dir = newLanguage.iso === 'ar' ? 'rtl' : 'ltr';

        // refresh needed to update caching of path rerouting to acccomodate for the language change
        // router.refresh();
    };

    useEffect(() => {
        const close = (Event: MouseEvent) =>
            !(Event.target as HTMLElement)?.closest('.amt-locales') && setOpen(false);

        document.addEventListener('click', close);
        return () => document.removeEventListener('click', close);
    }, [container]);

    return options.length < 2 ? (
        <></>
    ) : (
        <div
            className={clsx(
                'amt-locales',
                className,
                inline && 'is-inline',
                open && 'is-open',
                mobile && 'is-mobile'
            )}
            ref={container}
        >
            <button
                aria-activedescendant={open ? `${defaultOption.label}-${uid}` : ''}
                aria-controls={`list-${uid}`}
                aria-expanded={open}
                aria-haspopup="listbox"
                className="amt-locales-label"
                id={`label-${uid}`}
                onClick={handleOpenClick}
                role="combobox"
                tabIndex={0}
                title="Choose your language"
                // Since default state is dependent on client information from cookies, prerender will always assume English as the selected option and cause hydration issues when comparing text against server-rendered version, so we supress the warning
                suppressHydrationWarning
            >
                <Globe className="amt-locales-globe" />
                {defaultOption.label}
                <Arrow className="amt-locales-arrow" />
            </button>
            <div
                aria-labelledby={`label-${uid}`}
                className="amt-locales-options"
                id={`list-${uid}`}
                role="listbox"
                tabIndex={-1}
            >
                <div className="amt-locales-overflow">
                    <div className="amt-locales-list">
                        {options.map(({ label, value }) => (
                            <button
                                key={`${label}-${value}`}
                                aria-selected={
                                    value.toLocaleLowerCase() === language.iso.toLowerCase()
                                }
                                className={clsx(
                                    'amt-locales-option',
                                    value.toLowerCase() === language.iso.toLowerCase() &&
                                        'is-selected'
                                )}
                                id={`${label}-${uid}`}
                                onClick={() => handleLanguageSelect({ label, value })}
                                role="option"
                            >
                                {label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
