'use client';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';

import BurgerMenuIcon from '@/assets/images/burger-menu.svg';
import { useLanguageStore } from '@/features/site-language/store/language-store-provider';
import { SiteHeaderType } from '@/features/strapi/api/getSiteHeader';

import { Button } from '../button/Button.component';
import { MobileNavigation } from '../mobile-navigation/MobileNavigation.component';
import { Modal } from '../modal/Modal.component';

import styles from './GlobalHeader.module.scss';

type GlobalHeaderProps = {
    data: SiteHeaderType;
};

export const GlobalHeader = ({ data }: GlobalHeaderProps) => {
    const { language } = useLanguageStore((state) => state);
    const container = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const pathname = usePathname();
    const router = useRouter();

    const { logo_text, navigation_links, cta, logo } = data;

    const goToSection = (id: string): void => {
        if (!id) return;
        setIsOpen(false);

        // if homepage or careers page, scroll to section, otherwise reroute
        if (pathname.match(/^\/[\w-]*(\/careers)?$/i)) {
            const element = document.getElementById(id);

            element?.scrollIntoView({
                behavior: 'smooth',
            });
        } else {
            router.push(`/careers#${id}`);
        }
    };

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (!container.current) return;

            const width = container.current.offsetWidth;
            const scroll = container.current.scrollWidth;

            setIsMobile(width < scroll);
        });

        container.current && observer.observe(container.current);

        return () => observer.disconnect();
    }, [container, navigation_links]);

    useEffect(() => {
        const onPageLoad = () => {
            setLoaded(true);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <header
            className={clsx(styles.header, isMobile && styles.isMobile, {
                [styles.loaded]: loaded,
            })}
        >
            <h1 className={styles.logo}>
                <Link className={styles.logoLink} href={`${language.path}`}>
                    <Image
                        className=""
                        alt={logo_text}
                        src={logo.src}
                        width={logo.width}
                        height={logo.height}
                    />
                </Link>
            </h1>
            <div className={styles.desktop} ref={container}>
                <div className={styles.navigation}>
                    <nav className={styles.links}>
                        <ul>
                            {navigation_links.map(({ text, url }) => (
                                <li key={url}>
                                    <Link
                                        href={url.startsWith('/') ? `${language.path}${url}` : url}
                                        data-testid="nav-links"
                                    >
                                        {text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
                <div className={styles.joinBtn}>
                    <Button
                        onClick={() => goToSection('subscribe')}
                        extraClass="navbar-button"
                        data-testid="join-btn"
                    >
                        {cta.text}
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                        </svg>
                    </Button>
                </div>
            </div>
            <button
                data-testid="burgerMenu"
                className={styles.burgerMenu}
                onClick={() => setIsOpen(true)}
                title={'Burger Menu'}
            >
                <BurgerMenuIcon className={styles.burgerIcon} />
            </button>
            <Modal
                wrapperClass={styles.modalWrapper}
                overlayClass={styles.modalOverlay}
                closeClass={styles.modalClose}
                open={isOpen}
                close={() => setIsOpen(false)}
            >
                <MobileNavigation
                    headerData={data}
                    onClickLink={() => setIsOpen(false)}
                    goToSection={goToSection}
                />
            </Modal>
        </header>
    );
};
