export const defaultLanguage = { label: 'English', iso: 'en', match: 'en', path: '/en' };

export const defaultLanguages = [
    defaultLanguage,
    { label: 'Español (LATAM)', iso: 'es-MX', match: 'es-mx', path: '/es-mx' },
    { label: 'Português', iso: 'pt-BR', match: 'pt', path: '/pt-br' },
    { label: '한국어', iso: 'ko-KR', match: 'ko', path: '/ko-kr' },
    { label: 'Français', iso: 'fr-FR', match: 'fr', path: '/fr-fr' },
    { label: 'Deutsch', iso: 'de-DE', match: 'de', path: '/de-de' },
];

export const getFormattedLanguage = (language: string) => {
    //Strapi won't accept an all lowercase locale, so we have to ensure locale is always ll-UU format to avoid 404s on the API endpoints
    const splitLanguage = language.split('-');
    //Except English is only two letters, so conditional for that
    return splitLanguage[1] ? `${splitLanguage[0]}-${splitLanguage[1].toUpperCase()}` : language;
};
