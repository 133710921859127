'use client';

import { type ReactNode, createContext, useRef, useContext } from 'react';
import { useStore } from 'zustand';

import { defaultLanguage, defaultLanguages } from '../helpers/defaultLanguages';

import { createLanguageStore, defaultInitState, LanguageStore } from './language.store';

export type LanguageStoreApi = ReturnType<typeof createLanguageStore>;

export const LanguageStoreContext = createContext<LanguageStoreApi | undefined>(undefined);

export interface LanguageStoreProviderProps {
    initialLang?: string;
    children: ReactNode;
}

export const LanguageStoreProvider = ({ initialLang, children }: LanguageStoreProviderProps) => {
    const storeRef = useRef<LanguageStoreApi>();
    if (!storeRef.current) {
        const lang =
            defaultLanguages.find((l) => l.iso.toLowerCase() === initialLang?.toLowerCase()) ||
            defaultLanguage;

        storeRef.current = createLanguageStore({
            ...defaultInitState,
            language: lang,
        });
    }

    return (
        <LanguageStoreContext.Provider value={storeRef.current}>
            {children}
        </LanguageStoreContext.Provider>
    );
};

export const useLanguageStore = <T,>(selector: (store: LanguageStore) => T): T => {
    const languageStoreContext = useContext(LanguageStoreContext);

    if (!languageStoreContext) {
        throw new Error(`useLanguageStore must be used within LanguageStoreProvider`);
    }

    return useStore(languageStoreContext, selector);
};
