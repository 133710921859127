'use client';

import cx from 'clsx';
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import CloseIcon from '@/assets/images/close-icon.svg';

import styles from './Modal.module.scss';

export const Modal = ({
    children,
    overlayClass,
    wrapperClass,
    closeClass,
    close,
    open,
}: {
    children: JSX.Element | JSX.Element[];
    overlayClass?: string;
    wrapperClass?: string;
    closeClass?: string;
    close: React.MouseEventHandler;
    open: boolean;
}) => {
    useEffect(() => {
        ReactModal.setAppElement('body');
    }, []);

    return (
        <ReactModal
            overlayClassName={cx(styles.overlay, overlayClass)}
            className={cx(styles.wrapper, wrapperClass)}
            isOpen={open}
            onRequestClose={close}
            shouldCloseOnOverlayClick={true}
        >
            <button className={cx(styles.close, closeClass)} onClick={close}>
                <CloseIcon title="close" className={styles.closeIcon} />
            </button>
            {children}
        </ReactModal>
    );
};
