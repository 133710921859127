export const SUPPORTED_PLAYERS = [
    'dailymotion',
    'facebook',
    'kaltura',
    'mixcloud',
    'soundCloud',
    'streamable',
    'twitch',
    'vimeo',
    'wistia',
    'youtu',
];

export const cleanVideoUrl = (url: string) => {
    const temp = new URL(url);
    let cleaned = '';

    // This step is necessary as we don't want playlists or other information passed in with the URL.
    if (temp.hostname.includes('youtu.be')) {
        cleaned = `https://www.youtube.com/watch?v=${temp.pathname.replace('/', '')}`;
    } else if (temp.hostname.includes('youtube')) {
        cleaned = `https://www.youtube.com/watch?v=${temp.searchParams.get('v')}`;
    } else {
        cleaned = url;
    }

    return cleaned;
};

export const isSupportedVideo = (url: string) => {
    if (!url) return false;
    const temp = new URL(url);
    return SUPPORTED_PLAYERS.some((player) => temp.hostname.includes(player.toLowerCase()));
};
