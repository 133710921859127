export const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME as string;
export const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN as string;
export const INTERNAL_SITE_DOMAIN = process.env.NEXT_PUBLIC_INTERNAL_SITE_DOMAIN as string;

export const I18N_COOKIE = 'amt.krafton.language';
export const TIME_COOKIE = 'amt.krafton.time';

export const STRAPI_ENDPOINT = process.env.NEXT_PUBLIC_STRAPI_ENDPOINT as string;

export const AMT_EVENT_SERVICE = process.env.NEXT_PUBLIC_EVENT_SERVICE as string;
export const SUBSCRIBE_URL = `${AMT_EVENT_SERVICE}/optinnewsletter` as string;

export const ONETRUST = process.env.NEXT_PUBLIC_ONETRUST as string;

export const ABSTRACT_API_KEY = process.env.ABSTRACT_API_KEY as string;

export const KRAFTON_ID_BASE = process.env.NEXT_PUBLIC_KRAFTON_ID_AUTH as string;
export const KRAFTON_ID_REST = process.env.NEXT_PUBLIC_KRAFTON_ID_REST as string;

export const KRAFTON_ID_AUTHORITY = `${KRAFTON_ID_BASE}/oidc/`;
export const KRAFTON_ID_PROFILE = `${KRAFTON_ID_BASE}/oidc/me`;
export const KRAFTON_ID_LINK_ORIGIN = `${KRAFTON_ID_BASE}/auth/`;
export const KRAFTON_ID_WELL_KNOWN = `${KRAFTON_ID_BASE}/oidc/.well-known/openid-configuration`;

export const BANNER_LS_KEY = 'krafton.amt.bip.dismissed-site-banners' as string;

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;
