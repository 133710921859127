import { RefObject, useEffect, useState } from 'react';

type useInViewProps = {
    ref: RefObject<HTMLElement>;
    onlyOnce?: boolean;
    options?: IntersectionObserverInit;
};

export const useInView = ({ ref, onlyOnce, options = { threshold: 0.3 } }: useInViewProps) => {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        if (!ref.current) return () => observer.disconnect && observer.disconnect();

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setInView(true);
                } else if (!entry.isIntersecting && !onlyOnce) {
                    setInView(false);
                }
            });
        }, options);

        observer.observe(ref.current);

        return () => observer.disconnect && observer.disconnect();
    }, [ref, onlyOnce, options]);

    return { inView };
};
