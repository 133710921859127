// Numbers should always include image and device sizes from next.config.js
type WidthType =
    | 16
    | 32
    | 48
    | 64
    | 96
    | 128
    | 256
    | 320
    | 360
    | 400
    | 480
    | 512
    | 640
    | 720
    | 960
    | 1024
    | 1080
    | 1200
    | 1368
    | 1440
    | 1920
    | 2048
    | 3840;

export const hasImgAttrs = (attributes: string[]) =>
    ['width', 'height'].every((attr) => attributes.includes(attr));

export const image = (src: string, width: WidthType, quality = 75) =>
    `/_next/image?url=${src}&w=${width}&q=${quality}`;

export const placeholder = (url: string) => image(url, 16, 50);
