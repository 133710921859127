'use client';

import Image from 'next/image';
import Link from 'next/link';

import { LocalePicker } from '@/features/site-language/components/locale-picker/LocalePicker.component';
import { useLanguageStore } from '@/features/site-language/store/language-store-provider';
import { SiteHeaderType } from '@/features/strapi/api/getSiteHeader';

import { Button } from '../button/Button.component';

import styles from './MobileNavigation.module.scss';

export const MobileNavigation = ({
    headerData,
    onClickLink,
    goToSection,
}: {
    headerData: SiteHeaderType;
    onClickLink?: () => void;
    goToSection: (id: string) => void;
}) => {
    const { language } = useLanguageStore((state) => state);

    return (
        <>
            <h1 className={styles.logo}>
                <Link className={styles.logoLink} href={language.path} onClick={onClickLink}>
                    <Image
                        className=""
                        alt={headerData.logo_text}
                        src={headerData.logo.src}
                        width={headerData.logo.width}
                        height={headerData.logo.height}
                    />
                </Link>
            </h1>
            <nav className={styles.menu}>
                <div className={styles.innerWrapper}>
                    <ul>
                        {headerData.navigation_links.map(({ text, url }) => (
                            <li key={url}>
                                <Link
                                    href={url.startsWith('/') ? `${language.path}${url}` : url}
                                    onClick={onClickLink}
                                    data-testid="nav-links"
                                >
                                    {text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <Button
                        extraClass={styles.__navbarButton__mobile}
                        onClick={() => {
                            goToSection('subscribe');
                            onClickLink && onClickLink();
                        }}
                        data-testid="join-btn"
                    >
                        {headerData.cta.text}
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                        </svg>
                    </Button>
                    <LocalePicker className={styles.locale} mobile />
                </div>
            </nav>
        </>
    );
};
