'use client';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useRef } from 'react';

import { LocalePicker } from '@/features/site-language/components/locale-picker/LocalePicker.component';
import { useLanguageStore } from '@/features/site-language/store/language-store-provider';
import { SiteFooterType } from '@/features/strapi/api/getSiteFooter';
import { useInView } from '@/hooks/useInView';

import styles from './GlobalFooter.module.scss';

export const GlobalFooter = ({ data }: { data: SiteFooterType }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });
    const { language } = useLanguageStore((state) => state);

    const {
        copyright_notice,
        game_ratings,
        linked_logos,
        links,
        social_accounts,
        follow_us,
        legal_links,
    } = data;

    const copyrightText = copyright_notice.replace('<p>', `<p>&copy; ${new Date().getFullYear()} `);

    return (
        <footer className={clsx(styles.footer, { [styles.animate]: inView })} ref={containerRef}>
            <div className={styles.logosAndLinks}>
                <div className={styles.logosSection}>
                    <Image
                        className={styles.logo}
                        alt=""
                        src={linked_logos.src}
                        width={linked_logos.width}
                        height={linked_logos.height}
                        data-testid="linked-logo"
                    />
                    <span className={styles.divider} />
                    <ul className={styles.ratings}>
                        {game_ratings.map((rating) => (
                            <li key={rating.link} className={styles.rating}>
                                <Link
                                    href={rating.link}
                                    data-testid="rating-links"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {rating.icon?.svg ? (
                                        <i
                                            title={rating.rating}
                                            dangerouslySetInnerHTML={{ __html: rating.icon.svg }}
                                        />
                                    ) : (
                                        <Image
                                            alt={rating.rating}
                                            src={rating.icon.src}
                                            width={rating.icon.width}
                                            height={rating.icon.height}
                                        />
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.linksSection}>
                    <ul className={styles.links}>
                        {links.map(({ id, url, text }) => (
                            <li key={id}>
                                <Link
                                    href={url.startsWith('/') ? `${language.path}${url}` : url}
                                    data-testid={`links`}
                                >
                                    {text}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={styles.followUs}>
                    <span>{follow_us}</span>
                    <ul className={styles.socials}>
                        {social_accounts.map((account) => (
                            <li key={account.url}>
                                <Link
                                    href={account.url}
                                    data-testid="social-links"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {account.icon?.svg ? (
                                        <i
                                            title={account.name}
                                            dangerouslySetInnerHTML={{ __html: account.icon.svg }}
                                        />
                                    ) : (
                                        <Image
                                            alt={account.name}
                                            src={account.icon.src}
                                            width={account.icon.width}
                                            height={account.icon.height}
                                        />
                                    )}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <span className={styles.horizontalDivider} />
            <div className={styles.copyrightAndLegal}>
                <div
                    className={styles.copyright}
                    dangerouslySetInnerHTML={{ __html: copyrightText }}
                />
                <ul className={styles.links}>
                    {legal_links.map(({ id, url, text }) => (
                        <li key={id}>
                            {language.match === 'ko' && url === '/pages/privacy' ? (
                                <strong>
                                    <Link href={`${language.path}${url}`} data-testid="legal-links">
                                        {text}
                                    </Link>
                                </strong>
                            ) : (
                                <Link
                                    href={url.startsWith('/') ? `${language.path}${url}` : url}
                                    data-testid="legal-links"
                                >
                                    {text}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
            <LocalePicker className={styles.language} />
        </footer>
    );
};
