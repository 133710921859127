import clsx from 'clsx';
import parse, { Element } from 'html-react-parser';
import { sanitize } from 'isomorphic-dompurify';
import Image from 'next/image';

import { Video } from '@/components/video/Video.component';
import { domPurifyConfig, processMarkup } from '@/helpers/domPurifyHelpers';
import { hasImgAttrs } from '@/helpers/imageHelpers';
import { isSupportedVideo } from '@/helpers/videoHelpers';

import { TextBlockProps } from './TextBlock';
import styles from './TextBlock.module.scss';

export const TextBlock = ({
    content,
    className,
    resetText,
    imageOptimized = false,
}: TextBlockProps) => {
    const purified = sanitize(content, domPurifyConfig) as HTMLElement;
    const processed = processMarkup({ markup: purified, imageOptimized });

    const parsed = parse(processed, {
        replace: (domNode) => {
            if (!(domNode instanceof Element)) return;

            // Replace all supported video iframes with Video component
            if (domNode.name === 'iframe' && isSupportedVideo(domNode.attribs.src)) {
                const url = domNode.attribs.src.replace('embed/', 'watch?v=');
                return <Video url={url} isThumbnail />;
            }

            // Replace all images with next/image component
            if (domNode.name === 'img' && hasImgAttrs(Object.keys(domNode.attribs))) {
                const {
                    width = '0',
                    height = '0',
                    src = '',
                    alt = '',
                    srcset,
                    sizes,
                    loading,
                } = domNode.attribs;

                return (
                    <Image
                        width={parseInt(width)}
                        height={parseInt(height)}
                        src={src}
                        alt={alt}
                        {...(loading && { loading: loading as any })}
                        {...(srcset && { srcSet: srcset })}
                        {...(sizes && { sizes: sizes })}
                    />
                );
            }
        },
    });

    return <div className={clsx(className, { [styles.reset]: resetText })}>{parsed}</div>;
};
