import Cookies from 'js-cookie';
import { createContext } from 'react';
import { createStore } from 'zustand';

import { I18N_COOKIE } from '@/constants';

import {
    defaultLanguage,
    defaultLanguages,
    getFormattedLanguage,
} from '../helpers/defaultLanguages';
import { LanguagesType, LanguageType } from '../types';

export type LanguageState = {
    language: LanguageType;
    languages: LanguagesType;
    supported: string[];
};

export type LanguageActions = {
    setLanguage: (language: LanguageType) => void;
    setLanguages: (languages: LanguagesType) => void;
    setSupported: (languages: LanguagesType) => void;
};

export type LanguageStore = LanguageState & LanguageActions;

const initialLanguage = Cookies.get(I18N_COOKIE) || defaultLanguage.iso;

export const defaultInitState: LanguageState = {
    language:
        defaultLanguages.find((lang) => lang.iso === getFormattedLanguage(initialLanguage)) ||
        defaultLanguage,
    languages: defaultLanguages,
    supported: defaultLanguages.map((language) => language.iso),
};

export const createLanguageStore = (initState: LanguageState = defaultInitState) => {
    return createStore<LanguageStore>()((set, get) => ({
        ...initState,
        setLanguage: (language: LanguageType) => {
            Cookies.set(I18N_COOKIE, language.iso);
            set({ language });
        },
        setLanguages: (languages: LanguagesType) => set({ languages }),
        setSupported: (languages: LanguagesType) => {
            const currentLang = get().language;

            set({ languages });

            // Reset language if it's not supported at this time.
            if (!languages.some((lang) => lang.iso === currentLang.iso)) {
                set({ language: defaultLanguage });
            }
        },
    }));
};

export const LanguageContext = createContext<LanguageStore | null>(null);
