import clsx from 'clsx';
import Link from 'next/link';

import styles from './Button.module.scss';

interface ButtonType extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
    children: string | JSX.Element | (string | JSX.Element)[];
    kind?: 'primary' | 'secondary' | 'tertiary' | 'inline';
    href?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => any;
    extraClass?: string;
    download?: boolean;
    value?: string;
}

export const Button = ({
    children,
    href,
    onClick,
    extraClass,
    download,
    type = 'button',
    kind = 'primary',
    value,
    ...props
}: ButtonType) => {
    return href ? (
        <Link
            download={download}
            className={clsx(styles.button, styles[kind], extraClass)}
            href={href}
            onClick={onClick}
            {...props}
        >
            {children}
        </Link>
    ) : onClick || value || ['submit', 'reset'].includes(type) ? (
        <button
            className={clsx(styles.button, styles[kind], extraClass)}
            {...(onClick && { onClick })}
            {...(value ? { value } : { type })}
            {...props}
        >
            {children}
        </button>
    ) : (
        <span className={clsx(styles.button, styles[kind], extraClass)} {...props}>
            {children}
        </span>
    );
};
