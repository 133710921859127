'use client';

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { SUPPORTED_PLAYERS, cleanVideoUrl } from '@/helpers/videoHelpers';

import styles from './Video.module.scss';

const BilibiliPlayer = ({
    url,
    disable,
    className,
}: {
    url: string;
    disable: boolean;
    className: string | undefined;
}) => {
    const id = new URL(url).pathname.replace('/video/', '');
    return (
        <iframe
            loading="lazy"
            className={clsx(styles.video, className, {
                [styles.disabled]: disable,
            })}
            height="100%"
            src={`https://player.bilibili.com/player.html?aid=598443740&bvid=${id}&cid=772171712&page=1`}
            title="Bilibili Video Player"
            width="100%"
        />
    );
};

const YoukuPlayer = ({
    url,
    disable,
    className,
}: {
    url: string;
    disable: boolean;
    className: string | undefined;
}) => {
    const id = new URL(url).pathname.replace('/v_show/id_', '').replace('.html', '');
    return (
        <iframe
            loading="lazy"
            className={clsx(styles.video, className, {
                [styles.disabled]: disable,
            })}
            height="100%"
            src={`https://player.youku.com/embed/${id}==`}
            title="Youku Video Player"
            width="100%"
        />
    );
};

export const Video = ({
    url,
    className,
    isThumbnail = false,
    disable = false,
    playing = false,
    onPlay,
}: {
    url: string;
    className?: string;
    isThumbnail?: boolean;
    disable?: boolean;
    playing?: boolean;
    onPlay?: () => void;
}) => {
    const [type, setType] = useState('');
    const [isPlaying, setIsPlaying] = useState(playing);

    const handlePreviewClick = () => setIsPlaying(true);

    useEffect(() => {
        const newType = SUPPORTED_PLAYERS.some((string) => url.includes(string))
            ? 'generic'
            : url.includes('bilibili')
              ? 'bilibili'
              : url.includes('youku')
                ? 'youku'
                : 'unsupported';

        setType(newType);
    }, [url]);

    return type === 'generic' ? (
        <ReactPlayer
            data-testid="video"
            className={clsx(styles.video, className, disable && styles.disabled)}
            height="100%"
            url={cleanVideoUrl(url)}
            width="100%"
            light={isThumbnail}
            playIcon={disable ? <></> : <div className={styles.play}></div>}
            playing={isPlaying}
            onPlay={onPlay}
            controls={true}
            fallback={<></>}
            onClickPreview={handlePreviewClick}
        />
    ) : type === 'bilibili' ? (
        <BilibiliPlayer className={className} disable={isThumbnail} url={url} />
    ) : type === 'youku' ? (
        <YoukuPlayer className={className} disable={isThumbnail} url={url} />
    ) : (
        <></>
    );
};

export default Video;
